
/* Sudoku 0.3.9 [2019-06-27] */

/* --------- @todo ----------------------------------------------------------
  + timer should stop and go bold when puzzle is solved
  + when highlighting is on, also highlight same pencil marks (not just digits)
  + (game mode) diagonal boards
  + (settings option) disallow placement of digits that are complete
  + (settings option) game timer on/off
  + (settings option) toggle recording pencil marks in history on/off
  + (settings option) remove pencil marks from surrounding squares when placing a digit
    - ex. when there are one or more `2` pencil marks in a box and a `2` digit is placed, remove those marks
----------------------------------------------------------------------------- */

import React from 'react';
import ReactDOM from 'react-dom';
// contains both initial board states as well as full solutions for various board sizes
import Puzzles from './puzzles.json';
// the stylesheet; compiled from LESS
import './sudoku.css';

const VERSION_NUM = '0.3.4';
const VERSION_DATE = '2019-06-28';


// individual square node; called from Board{}::renderSquare()
class Square extends React.Component {
  renderPencilMarks(markedDigits) {
    let marks = [];
    let markClass = '';

    // go through each available pencil mark digit for placement
    markedDigits.forEach((mark, index) => {
      markClass = 'mark';
      if (mark) {
        markClass += ' show';
      }

      // each square contains all pencil mark digits to standardize placement. css will handle which digits are visible
      marks.push(<span key={index} className={markClass}>{index + 1}</span>);
    });

    return marks;
  }
  render() {
    let digitDisplay = '';
    let element = '';
    let cellClass = 'cell';

    if (this.props.initialDigit) {
      // when this square contains a digit from the initial board state (no user-placed digit or mark)
      digitDisplay = this.props.initialDigit;
    } else if (this.props.placedDigit) {
      // when a user-placed digit is in this square
      cellClass += ' placed';
      digitDisplay = this.props.placedDigit;
    } else if (this.props.marks.some((mark) => { return mark === true; })) {
      // when there are pencil marks for this square
      cellClass += ' marks';
      digitDisplay = this.renderPencilMarks(this.props.marks);
    }
    if (this.props.current) {
      // when this is the actively-selected (clicked) square
      cellClass += ' current';
    }
    if (this.props.digitHighlight) {
      // when there is a same-digit highlight for this square (based on preferences & last-clicked square value)
      cellClass += ' digit-highlight';
    }
    if (this.props.cellHighlight) {
      // when there is a row/column highlight indicator for the current square (based on preferences & last-clicked square value)
      cellClass += ' cell-highlight';
    }

    if (this.props.onClick) {
      // standard board
      element = (<div className={cellClass} onClick={this.props.onClick}>{digitDisplay}</div>);
    } else {
      // shadow board (for history display on mouse over)
      element = (<div className={cellClass}>{digitDisplay}</div>);
    }

    return element;
  }
}

// [boardSize]x[boardSize] game board; current display of numbers 1-[boardSize] based on initial state, digit placement history and pencil marks
class Board extends React.Component {
  // display each square. one of: empty, initial digit, user-placed digit or pencil marks
  renderSquare(squareIndex) {
    // calls Square{}::render() for each board square iteration 1-[boardSize]
    return (
      <Square
        key={squareIndex}
        placedDigit={this.props.placedDigits[squareIndex]}
        initialDigit={this.props.initialDigits[squareIndex]}
        marks={this.props.marks[squareIndex]}
        current={this.props.currentSquare[squareIndex]}
        digitHighlight={this.props.digitHighlights[squareIndex]}
        cellHighlight={this.props.rowColumnHighlights[squareIndex]}
        onClick={() => this.props.onClick(squareIndex)}
      />
    );
  }
  // display the board: [boardSize] rows of [boardSize] columns
  render() {
    // set the correct class for the board container based on puzzle size
    const boardClass = 'board b'+ this.props.boardSize;
    let boardSquares = [];

    // render each square individually and compose a board from them
    for (let s = 0; s < this.props.boardSize * this.props.boardSize; s++) {
      boardSquares.push(this.renderSquare(s));
    }

    return (<div className={boardClass}>{boardSquares}</div>);
  }
}

// [boardSize]x[boardSize] shadow game board; history of numbers 1-[boardSize] shown on history element mouse hover
class ShadowBoard extends React.Component {
  // display each square. one of: empty, user-placed digit or pencil marks
  renderSquare(squareIndex) {
    // calls Square{}::render() for each board square iteration
    return (
      <Square
        key={squareIndex}
        placedDigit={this.props.placedDigits[squareIndex]}
        initialDigit={this.props.initialDigits[squareIndex]}
        marks={this.props.marks[squareIndex]}
      />
    );
  }
  // display the board: [boardSize] rows of [boardSize] columns
  render() {
    // determine whether to show the history based on hover state and set the board size
    const boardClass = 'shadow-board b'+ this.props.boardSize;
    const shadowClass = this.props.shown ? boardClass+ ' shown' : boardClass;
    let boardSquares = [];

    // render each square individually and compose a board from them
    for (let s = 0; s < this.props.boardSize * this.props.boardSize; s++) {
      boardSquares.push(this.renderSquare(s));
    }

    return (<div className={shadowClass}>{boardSquares}</div>);
  }
}

// digits grid for selecting number to place on the board
class DigitPicker extends React.Component {
  renderDigit(digitIndex) {
    // visual indication of currently-selected digit
    const digitClass = this.props.activeDigit[digitIndex] ? 'digit active' : 'digit';

    return (
      <span
        key={'digit'+ digitIndex}
        className={digitClass}
        onClick={() => this.props.clickDigit(digitIndex)}
      >
        {digitIndex + 1}
      </span>
    );
  }
  renderClearDigit() {
    // visual indication of clear button selection
    const clearClass = this.props.clearDigit ? 'clear active' : 'clear';

    return (
      <span
        key="clearDigit"
        title="clear selected square"
        className={clearClass}
        onClick={() => this.props.clickClear()}
      >
        {'X'}
      </span>
    );
  }
  render() {
    // set the correct class for the digit-picker container based on board size
    const pickerClass = 'digit-picker p'+ this.props.boardSize;
    let pickerSquares = [];

    // put each number in its own square based on board size
    for (let s = 0; s < this.props.boardSize; s++) {
      pickerSquares.push(this.renderDigit(s));
    }
    // add a "clear square" button at the bottom
    pickerSquares.push(this.renderClearDigit());

    // the container of digits
    return (<div className={pickerClass}>{pickerSquares}</div>);
  }
}

// digits grid for selecting a pencil mark number to place on the board (for easing solution)
class MarkPicker extends React.Component {
  renderDigits(digitIndex) {
    let digits = [];
    let pencilClass = 'p';

    // go through each available digit for placement
    for (let mark = 0; mark < this.props.boardSize; mark++) {
      pencilClass = 'p';
      // indicator for what pencil number will be placed when selecting this square
      if (mark === digitIndex) {
        pencilClass += ' bold';
      }

      // each pencil mark square contains all digits
      digits.push(<span key={'mark'+ mark} className={pencilClass}>{mark + 1}</span>);
    }

    return digits;
  }
  renderBox(boxIndex) {
    // visual indication of currently-selected pencil mark digit
    const digitClass = this.props.activeMark[boxIndex] ? 'digit active' : 'digit';

    return (
      <div
        key={'markBox'+ boxIndex}
        className={digitClass}
        onClick={() => this.props.clickMark(boxIndex)}
      >
        {this.renderDigits(boxIndex)}
      </div>
    );
  }
  render() {
    // set the correct class for the pencil-picker container based on puzzle size
    const pickerClass = 'pencil-picker p'+ this.props.boardSize;
    let pickerSquares = [];

    for (let s = 0; s < this.props.boardSize; s++) {
      pickerSquares.push(this.renderBox(s));
    }

    return (<div className={pickerClass}>{pickerSquares}</div>);
  }
}

// allows the user to choose from various available puzzles
class PuzzleSelector extends React.Component {
  constructor(props) {
    super(props);

    this.difficulties = {
      '1': 'simple',
      '2': 'easy',
      '3': 'medium',
      '4': 'hard',
      '5': 'difficult',
      '6': 'awful',
      '7': 'evil',
      '8': 'diabolical',
      '9': 'impossible'
    };
    this.state = {
      showWarning: false,
      newBoardSize: 0,
      newPuzzleIndex: 0
    };
  }
  // prompt the user before changing the puzzle (yes/no dialog)
  warnBeforeChange(boardSize, puzzleIndex) {
// @todo is there not a way to do this without granular-level change [in this class]?
    // pause the game and timer
    this.props.togglePause(true);
    // show the warning dialog and pass desired puzzle selection
    this.setState({
      showWarning: true,
      newBoardSize: Math.abs(boardSize),
      newPuzzleIndex: Math.abs(puzzleIndex)
    });
  }
  // user does not want to change the puzzle (clicked 'no')
  cancel() {
    // un-pause the game and timer
    this.props.togglePause(false);
    // hide the warning dialog
    this.setState({ showWarning: false });
  }
  // user confirmed action to change to a different puzzle
  changePuzzle() {
    // tell Game{} to swap puzzles (and reset board state / timer)
    this.props.changePuzzle(this.state.newBoardSize, this.state.newPuzzleIndex);
    // hide the yes/no dialog
    this.setState({ showWarning: false });
  }
  render() {
    const warningClass = this.state.showWarning ? 'puzzle-warning show' : 'puzzle-warning';
    let puzzleSelection = [];
    let activeClass = '';

// @todo from MDN: If there is a need for certain ordering then the array should be sorted first like Object.entries(obj).sort((a, b) => b[0].localeCompare(a[0]))
    for (let [boardSize, puzzleList] of Object.entries(Puzzles)) {
      for (let puzzleIndex = 0; puzzleIndex < puzzleList.length; puzzleIndex++) {
        activeClass = 'puzzle';
        if (
          Math.abs(boardSize) === Math.abs(this.props.boardSize) &&
          Math.abs(puzzleIndex) === Math.abs(this.props.puzzleIndex))
        {
          activeClass += ' active';
        }

        puzzleSelection.push(
          <span
            key={boardSize+ '_'+ puzzleIndex}
            onClick={() => this.warnBeforeChange(boardSize, puzzleIndex)}
            className={activeClass}
          >
            {boardSize+ 'x'+ boardSize+ ' '+ this.difficulties[puzzleList[puzzleIndex].difficulty]}
          </span>
        );
      }
    }

    return (
      <div className="puzzle-selector-wrapper">
        <div className="puzzle-selector">{puzzleSelection}</div>
        <div className={warningClass}>
          <p><span>{'warning:'}</span>{' this will clear your progress.'}</p>
          <div className="buttons">
            <span onClick={() => this.changePuzzle()}>{'wipe it.'}</span>
            <span onClick={() => this.cancel()}>{'no, wait!'}</span>
          </div>
        </div>
      </div>
    );
  }
}

// allow the user to set various options such as how to place digits or allow certain behavior
class PuzzleSettings extends React.Component {
  render() {
// @todo is there a way of doing this without all the props passing? as in have <Game> call <Settings>.state for values?
    const activeClass = {};
    const settingsClass = this.props.shown ? 'active' : '';

    // placement has two options:
    // 1. [default] `pick-last`: choose the square, then either digit or pencil mark to place in it
    // 2. `pick-first`: choose the digit or pencil mark, then the square to place it in
    activeClass.pickLast = this.props.userSettings.placement === 'pick-last' ? 'active' : null;
    activeClass.pickFirst = this.props.userSettings.placement === 'pick-first' ? 'active' : null;
    // hinting has three options:
    // 1. [default] `full`: highlights row and column plus same digits in other squares
    // 2. `some`: highlights same digits in other squares
    // 3. `paper`: only indicates current square
    activeClass.hintingFull = this.props.userSettings.hinting === 'full' ? 'active' : null;
    activeClass.hintingSome = this.props.userSettings.hinting === 'some' ? 'active' : null;
    activeClass.hintingPaper = this.props.userSettings.hinting === 'paper' ? 'active' : null;
    // history has two options:
    // 1. [default] `digits`: include only digits, not include pencil marks
    // 2. `marks`: include digits and pencil marks
    /*
    activeClass.historyDigits = this.props.userSettings.history === 'digits' ? 'active' : null;
    activeClass.historyMarks = this.props.userSettings.history === 'marks' ? 'active' : null;
    */

    return (
      <div className="settings-wrapper">
        <div className="settings">
          <span className={settingsClass} onClick={() => this.props.toggleSettings()}>{'settings'}</span>
          <ul className={settingsClass}>
            <li>
              <strong>{'placement'}</strong>
              <span
                className={activeClass.pickLast}
                onClick={() => this.props.changeSetting('placement', 'pick-last')}
              >
                {'pick last'}
              </span>
              <span
                className={activeClass.pickFirst}
                onClick={() => this.props.changeSetting('placement', 'pick-first')}
              >
                {'pick first'}
              </span>
              <p>{'[default] pick last: select a square on the board, then choose a digit or pencil mark to place inside.'}</p>
              <p>{'pick first: select a digit or pencil mark, then place it inside a square on the board.'}</p>
            </li>
            <li>
              <strong>{'hinting'}</strong>
              <span
                className={activeClass.hintingFull}
                onClick={() => this.props.changeSetting('hinting', 'full')}
              >
                {'full'}
              </span>
              <span
                className={activeClass.hintingSome}
                onClick={() => this.props.changeSetting('hinting', 'some')}
              >
                {'some'}
              </span>
              <span
                className={activeClass.hintingPaper}
                onClick={() => this.props.changeSetting('hinting', 'paper')}
              >
                {'paper'}
              </span>
              <p>{'[default] full: highlight other same digits on the board and current row and column.'}</p>
              <p>{'some: selected square will highlight all other same digits on the board.'}</p>
              <p>{'paper: nothing is highlighted except current square; a lot like doing sudoku on paper.'}</p>
            </li>
            {/*
            <li>
              <strong>{'history'}</strong>
              <span
                className={activeClass.historyDigits}
                onClick={() => this.props.changeSetting('history', 'digits')}
              >
                {'digits'}
              </span>
              <span
                className={activeClass.historyMarks}
                onClick={() => this.props.changeSetting('history', 'marks')}
              >
                {'marks'}
              </span>
              <p>{'[default] digits: save only digit placement in history.'}</p>
              <p>{'marks: save both digits and pencil marks in history.'}</p>
            </li>
            */}
          </ul>
        </div>
      </div>
    );
  }
}

// game timer. starts at 0:00, begins counting from first placement and counts up until solved = true
class PuzzleTimer extends React.Component {
  padDigits(d) {
    return d > 9 ? d : "0"+ d;
  }
  render() {
    const seconds = this.padDigits(this.props.timer % 60);
    const minutes = parseInt(this.props.timer / 60, 10);
    const imageClass = this.props.stepNumber > 0 ? 'shown' : '';
    const toggleImage = this.props.paused ? 'play' : 'pause';

    if (this.props.stepNumber > 0 && !this.props.paused) {
      this.props.timerStart();
    }

    return (
      <div className="timer">
        <span>{minutes}:{seconds}</span>
        <img
          className={imageClass}
          src={toggleImage+ '.svg'}
          alt={toggleImage}
          onClick={() => this.props.togglePause()}
        />
      </div>
    )
  }
}

// version information, last build date and current development status
class DevInfo extends React.Component {
  render() {
    // various methods available: https://shripadk.github.io/react/docs/jsx-gotchas.html
    //<div className="version">{[<span>&alpha;</span>, '0.0.1']}</div>
    //<div className="version">{''+ String.fromCharCode(945)+ '0.1.0'}</div>

    return (
      <div className="version">
        {String.fromCharCode(945)} {VERSION_NUM} <span>{VERSION_DATE}</span>
      </div>
    );
  }
}

// primary structure containing game logic and calls to render methods
class Game extends React.Component {
  // called on game init
  constructor(props) {
    // always call super when constructing properties
    super(props);

// @todo how can this be set up without doing this twice (i want to just call selectPuzzle instead)?
    const boardSize = 9;
    const puzzleIndex = 0;
    // the solution for the selected puzzle
    const solution = Puzzles[boardSize][puzzleIndex].solution;
    // the initial board state for the above solution based on the selected puzzle
    const initialDigits = Puzzles[boardSize][puzzleIndex].initial;
    // begin the game with no pencil marks. an array of arrays, one for each square on the board and each square has [number of digits] possible marks
    const initialMarks = Array(boardSize * boardSize).fill(Array(boardSize).fill(false));
    // holds a one-second precision timer when the clock starts
    this.timerInterval = null;

    this.state = {
      // how many digits are available for possible placement on the board. determines grid size and used as primary index for puzzle selection (see puzzles.json)
      boardSize: boardSize,
      // which puzzle to solve (secondary index) for the board size chosen (see puzzles.json)
      puzzleIndex: puzzleIndex,
      // the complete solution for the puzzle. used to check against current board state
      solution: solution,
      // initial board state for the known solution, based on the selected puzzle
      initialDigits: initialDigits,
      // will be a two-dimensional array: one array for each board square which contains an array of possible marks
      initialMarks: initialMarks,
      // active (clicked) reference for css class placement (only affects display) when picking a digit
      activeDigit: Array(boardSize).fill(false),
      // active (clicked) reference for css class placement when selecting clear digit (located below pick-a-digit grid)
      clearDigit: false,
      // active (clicked) reference for css class placement (only affects display) when picking a pencil mark digit [to help with solution]
      activeMark: Array(boardSize).fill(false),
      // visual indication of currently-selected board square
      currentSquare: Array(boardSize * boardSize).fill(false),
      // highlight reference for css class placement (only affects display) for current/adjacent squares
      digitHighlights: Array(boardSize * boardSize).fill(false),
      rowColumnHighlights: Array(boardSize * boardSize).fill(false),
      // infinite board state history for each square containing a number from 1 to boardSize. starts at a pre-determined initial board state to begin solving from
      history: [{
        digits: initialDigits.slice(),
        marks: initialMarks.slice()
      }],
      // current history step number; used in conjunction with history for time travel/undo
      stepNumber: 0,
      // holds the history step number based on link hover to display the correct shadow [history] overlay
      historyNumber: 0,
      // selectable number from the number grid; will be used to place a number when clicking a board square
      digitToPlace: null,
      // selectable pencil mark from the pencil grid; will be used to place a pencil mark when clicking a board square. initially unset because on game start, regular board number one will be active
      markToPlace: null,
      // toggles solved display class and text
      solved: false,
      // toggles class for board history overlay on hover
      showShadow: false,
      // toggles class for settings modal open/close
      showSettings: false,
      // singular source because putting the timer inside of PuzzleTimer{} will create infinite loop issues due to having to reset its value back to zero when the puzzle is changed
      timer: 0,
      // toggles the timer and digit/mark placement possibility
      paused: false,
      // pre-defined (user modifiable) settings for things like placement mode and hinting
      settings: {
        // one of: `pick-last` (choose square then number) or `pick-first` (choose number then square)
        placement: 'pick-last',
        // one of: `full` (some + row & column), `some` (highlight same digits) or `paper` (no highlighting or coloring)
        hinting: 'full'
        // one of: `digits` (just digits) or `marks` (digits and marks)
        //history: 'digits'
      }
    };
  }
  // change the current puzzle
  changePuzzle(boardSize, puzzleIndex) {
    // the solution for the selected puzzle
    const solution = Puzzles[boardSize][puzzleIndex].solution;
    // the initial board state for the above solution based on the selected puzzle
    const initialDigits = Puzzles[boardSize][puzzleIndex].initial;
    // begin the game with no pencil marks. an array of arrays, one for each square on the board and each square has [number of digits] possible marks
    const initialMarks = Array(boardSize * boardSize).fill(Array(boardSize).fill(false));

    // stop the timer from ticking
    clearInterval(this.timerInterval);
    // wipe the entire game and replace it with the selected puzzle (or initiate a pre-chosen puzzle on first load)
    this.setState({
      boardSize: boardSize,
      puzzleIndex: puzzleIndex,
      solution: solution,
      initialDigits: initialDigits,
      initialMarks: initialMarks,
      activeDigit: Array(boardSize).fill(false),
      clearDigit: false,
      activeMark: Array(boardSize).fill(false),
      currentSquare: Array(boardSize * boardSize).fill(false),
      digitHighlights: Array(boardSize * boardSize).fill(false),
      rowColumnHighlights: Array(boardSize * boardSize).fill(false),
      history: [{
        digits: initialDigits.slice(),
        marks: initialMarks.slice()
      }],
      stepNumber: 0,
      historyNumber: 0,
      digitToPlace: null,
      markToPlace: null,
      solved: false,
      showShadow: false,
      showSettings: false,
      timer: 0,
      paused: false
    });
  }
  // when a digit is picked from the grid next to the board; updates game state for next number placement
  digitClick(digitIndex) {
    // remove active class from the pencil mark grid
    const activeMark = this.state.activeMark.map(() => { return false; });
    // remove active class from the digit picker grid (next statement sets active digit)
    const activeDigit = this.state.activeDigit.map(() => { return false; });
    // current square index based on first 'true' index
    const squareIndex = this.state.currentSquare.indexOf(true);

    if (this.state.settings.placement === 'pick-first') {
      // set the currently-selected digit's css class to show as active
      activeDigit[digitIndex] = true;
      // set the board state for the next square placement to be the selected digit. also cancel-out any pencil digit placement
      this.setState({
        clearDigit: false, // css
        activeMark: activeMark, // css
        activeDigit: activeDigit, // css
        digitToPlace: digitIndex + 1,
        markToPlace: null
      });
    } else {
      this.makeMove(squareIndex, digitIndex + 1);
    }
  }
  // when clear square "X" is picked from the digit grid; cancels out active digit placement. when this is selected, any board square click will clear out its value
  clearClick() {
    // remove active class from the digit picker grid
    const activeDigit = this.state.activeDigit.map(() => { return false; });
    // remove active class from the pencil mark grid
    const activeMark = this.state.activeMark.map(() => { return false; });
    // current square index based on first 'true' index
    const squareIndex = this.state.currentSquare.indexOf(true);

    if (this.state.settings.placement === 'pick-first') {
      // set the board state for next click to be clear the current square
      this.setState({
        clearDigit: true, // css
        activeDigit: activeDigit, // css
        activeMark: activeMark, // css
        digitToPlace: null,
        markToPlace: null
      });
    } else {
      this.makeMove(squareIndex, null, null, true);
    }
  }
  // when a pencil mark digit is picked; updates game state for next pencil mark placement
  markClick(markIndex) {
    // remove active class from the number picker grid
    const activeDigit = this.state.activeDigit.map(() => { return false; });
    // reset the active class from the pencil mark grid (next statement sets active mark)
    const activeMark = this.state.activeMark.map(() => { return false; });
    // current square index based on first 'true' index
    const squareIndex = this.state.currentSquare.indexOf(true);

    if (this.state.settings.placement === 'pick-first') {
      // set the current-selected pencil mark digit's css class to show as active
      activeMark[markIndex] = true;
      // set the board state for the next pencil mark placement to be selected digit. also cancel-out any regular digit placement
      this.setState({
        clearDigit: false, // css
        activeMark: activeMark, // css
        activeDigit: activeDigit, // css
        digitToPlace: null,
        markToPlace: markIndex + 1
      });
    } else {
      this.makeMove(squareIndex, null, markIndex + 1);
    }
  }
  // checks the current board state against the known solution
  checkSolution(digitsState) {
    // representation of currently-selected puzzle's final solution for comparison
    const solution = this.state.solution;
    // when the user's arrangement of numbers exactly matches the solution
    return digitsState.every((digit, square) => { return digit === solution[square]; });
  }
  makeMove(squareIndex, digitToPlace, markToPlace, clearDigit) {
// @todo this whole routine used to be in squareClick() but due to allowing settings to determine method of placement, it has been separated into its own function. i feel like this is slightly more dirty than it's supposed to be but it works for now
    // copy of the entire move (digits and marks) placement history
    const moveHistory = this.state.history.slice(0, this.state.stepNumber + 1);
    // copy of the most-recent move placement history
    const currentMove = moveHistory[moveHistory.length - 1];
    // copy of the current (most recent) board state to compare against the known solution
    const digitsState = currentMove.digits.slice();
    // copy of the current pencil marks state to show/hide each pencil mark for a given square. slice cannot handle "deep" arrays, so use map() to make a multi-dimension copy
    const marksState = currentMove.marks.map((mark) => { return mark.slice(); });
    // puzzle solved state true/false
    let puzzleSolved = false;

    // 1. do not allow placement of any digit or pencil mark over the initial board state
    // 1a. do not record history if no digit/mark is selected
    // 1b. clear the square when clear digit is selected
    if (
      squareIndex >= 0 &&
      !this.state.initialDigits[squareIndex] &&
      (digitToPlace || markToPlace || clearDigit)
    ) {
      if (digitToPlace) {
        // when a regular digit is selected:
        // place the currently-selected digit into the copied board state
        digitsState[squareIndex] = digitToPlace;
      } else if (markToPlace) {
        // when a pencil mark digit is selected:
        // remove any current digit from this square
        digitsState[squareIndex] = null;
        // set the flag for this pencil mark digit to either show or hide based on current state
        marksState[squareIndex][markToPlace - 1] = !marksState[squareIndex][markToPlace - 1];
      } else if (clearDigit) {
        // when clear digit is selected:
        // remove any current digit from this square
        digitsState[squareIndex] = null;
        // remove all pencil marks from this square
        marksState[squareIndex] = Array(this.state.boardSize).fill(false);
      }
      // check the current board state against the known solution
      if (this.checkSolution(digitsState)) {
        puzzleSolved = true;
      }

      // update the game state and increment the step (for history/undo)
      this.setState({
        // replace the history with the current board state
        history: moveHistory.concat({
          digits: digitsState,
          marks: marksState
        }),
        // increment the step
        stepNumber: moveHistory.length,
        // indicate whether or not user has completed the puzzle
        solved: puzzleSolved
      });
    }
  }
  // place a number or pencil mark in a square on the board or clear current square. disallows placement of digits or marks over the initial board
  squareClick(squareIndex) {
    // copy of the entire move (digits and marks) placement history
    const moveHistory = this.state.history.slice(0, this.state.stepNumber + 1);
    // copy of the most-recent move placement history
    const currentMove = moveHistory[moveHistory.length - 1];
    // copy of the current (most recent) board state to compare against the known solution
    const digitsState = currentMove.digits.slice();
    // reference for visual indication of currently selected square
    const currentSquare = this.state.currentSquare.map(() => { return false; });
    // reference for indicating same digits in other squares
    const digitHighlights = this.state.digitHighlights.map(() => { return false; });
    // reference for highlighting rows and columns
    const rowColumnHighlights = this.state.rowColumnHighlights.map(() => { return false; });
    // highlighting references
    const b = this.state.boardSize;
    const columnSquares = [];
    const rowSquares = [];

    // when user settings specify choose a digit/mark first then place in square
    if (this.state.settings.placement === 'pick-first') {
      this.makeMove(squareIndex, this.state.digitToPlace, this.state.markToPlace, this.state.clearDigit);
    }

    // when user settings allow all or some highlighting
    if (this.state.settings.hinting !== 'paper') {
      // when there is a digit in this square
      if (digitsState[squareIndex] !== null) {
        // find other same digits and highlight them (as well as current square's digit)
        for (let i = 0; i < digitsState.length; i++) {
          if (digitsState[i] === digitsState[squareIndex]) {
            digitHighlights[i] = true;
          }
        }
      }

// @todo this is incredibly dirty. LEARN MATH FFS
      // when settings allow for full highlighting, indicate rows and columns also
      if (this.state.settings.hinting === 'full') {
        for (let i = b; i > 0; i--) {
          columnSquares.push(squareIndex - (b * i));
        }
        for (let i = 0; i < b; i++) {
          columnSquares.push(squareIndex + (b * i));
        }
        const rowBegin = Math.floor(squareIndex / b) * b;
        for (let i = rowBegin; i < rowBegin + b; i++) {
          rowSquares.push(i);
        }
        for (let i = 0; i < columnSquares.length; i++) {
          if (columnSquares[i] >= 0 && columnSquares[i] <= digitsState.length) {
            rowColumnHighlights[columnSquares[i]] = true;
          }
        }
        for (let i = 0; i < rowSquares.length; i++) {
          rowColumnHighlights[rowSquares[i]] = true;
        }
      }

      // update the board state with appropriate highlighting
      this.setState({
        digitHighlights: digitHighlights,
        rowColumnHighlights: rowColumnHighlights
      });
    }

    // always indicate [visually] what the currently-selected square is
    currentSquare[squareIndex] = true;
    this.setState({ currentSquare: currentSquare });
  }
  // reset, go to history step #, undo, redo
  timeTravel(step) {
    let puzzleSolved = false;

    // check the current board state against the known solution
    if (this.checkSolution(this.state.history[step].digits)) {
      puzzleSolved = true;
    }
    // update the internal game state to reflect the chosen point in time
    this.setState({
      stepNumber: step,
      solved: puzzleSolved
    });
  }
  // sets various options such as display mode, placement method and highlighting
  changeSetting(key, value) {
// @todo is this actually a copy or is it passing by reference again?
    let newSettings = this.state.settings;

    newSettings[key] = value;
    this.setState({ settings: newSettings });

    if (key === 'placement' && value === 'pick-last') {
      // when changing placement method, visually de-select any digits or marks
      this.setState({
        activeDigit: this.state.activeDigit.map(() => { return false; }),
        clearDigit: false,
        activeMark: this.state.activeMark.map(() => { return false; })
      });
    }
    if (key === 'hinting') {
      if (value === 'some') {
        // when changing hinting mode to digits only, remove row/column highlights
        this.setState({ rowColumnHighlights: this.state.rowColumnHighlights.map(() => { return false; }) });
      } else if (value === 'paper') {
        // when changing to paper mode, clear all highlights
        this.setState({
          digitHighlights: this.state.digitHighlights.map(() => { return false; }),
          rowColumnHighlights: this.state.rowColumnHighlights.map(() => { return false; })
        });
      }
    }
  }
  // show/hide the settings dialog
  toggleSettings() {
    if (!this.state.paused && !this.state.showSettings) {
      this.togglePause(true);
    } else {
      this.togglePause();
    }
    this.setState({ showSettings: !this.state.showSettings });
  }
  // changes between active and inactive for placement (and affects timer)
  togglePause(forcePause) {
    // do not do anything if the game hasn't been started
    if (this.state.stepNumber > 0) {
      if ((forcePause === undefined || forcePause === false) && this.state.paused) {
        this.timerStart();
        this.setState({ paused: false });
      } else {
        clearInterval(this.timerInterval);
        this.setState({ paused: true });
      }
    }
  }
  timerStart() {
    clearInterval(this.timerInterval);
    this.timerInterval = setInterval(this.timerTick.bind(this), 1000);
  }
  // increments the timer every second while the puzzle is actively being solved (not paused, after first placement)
  timerTick() {
    this.setState({ timer: this.state.timer + 1 });
  }
  // resets the timer to zero when the puzzle is changed or the user wipes the board (to start the same puzzle over)
  timerReset() {
    clearInterval(this.timerInterval);
    this.setState({ timer: 0 });
  }
// @todo consider using a click-away library if this gets too hairy
  // handle clicks anywhere; either reject the click or hide a modal (like settings menu)
  clickAnywhere(evt) {
    if (!evt.target.closest('.settings') && this.state.showSettings) {
      this.toggleSettings();
    }
  }
  // display the game: board (grid of numbers), selectable number matrix 1-[boardSize], selectable pencil mark matrix 1-[boardSize] and time travel/move history (undo/redo)
  render() {
    // copy of the game's move history
    const moveHistory = this.state.history;
    // obtain the current board state for rendering from the move history based on the provided step number
    // note: until a history step is clicked, the board will reflect the most recently-placed numbers
    const currentMove = moveHistory[this.state.stepNumber];
// @todo this ternary seems pointless. i thought it was either current or -1 so look at it
    // holds the last known move for board history display
    const historyMove = this.state.historyNumber > 0 ?
      moveHistory[this.state.historyNumber] :
      moveHistory[this.state.historyNumber];
// @todo move historyDisplay into its own Class?
    // list of recent moves for history traversal
    const historyDisplay = moveHistory.map((step, move) => {
      // for each number that was placed, allow user to click back through their move history
      const historyItem = move === 0 ? 'reset' : move;
      let historyItemClass = move === this.state.stepNumber ? 'active' : '';

      if (historyItem === 'reset') {
        historyItemClass += ' reset';
      }

      return (
        <span
          key={move}
          className={historyItemClass}
          onClick={() => this.timeTravel(move)}
          onMouseEnter={() => this.setState({ showShadow: true, historyNumber: move })}
          onMouseOut={() => this.setState({ showShadow: false })}
        >
          {historyItem}
        </span>
      );
    });
    // changes color of the status display based on whether puzzle is solved
    const statusClass = this.state.solved ? 'status solved' : 'status';
    // sets the width of the primary container based on board size
    let mainClass = 'b'+ this.state.boardSize;
    // show/hide an overlay preventing moves when game is paused
    let pausedClass = 'board-overlay';
    let undoClass = 'undo';
    let undoStep = null;
    let redoClass = 'redo';
    let redoStep = null;

    // when the game is paused, blur board and pickers and overlay a block so there can be no clicking
    if (this.state.paused) {
      mainClass += ' paused';
      pausedClass += ' shown';
    }
    // when there are history entries and not beginning board state
    if (this.state.stepNumber > 0) {
      undoClass += ' shown';
      undoStep = this.state.stepNumber - 1;
    }
    // when there are history entries and not on the current step
    if (this.state.history.length > 1 && this.state.stepNumber + 1 < this.state.history.length) {
      redoClass += ' shown';
      redoStep = this.state.stepNumber + 1;
    }

    // clickAnywhere() handles actions for rejection purposes like closing an open settings menu
    return (
      <div className="game" id="sudokuGame" onClick={(evt) => this.clickAnywhere(evt)}>
        {/* display a screen-covering warning to users with displays that are too small */}
        <div className="resolution-warning">
          <p>{'The lowest resolution currently supported is 900x580.'}</p>
          <p>{'A responsive version is in development.'}</p>
        </div>
        {/* choose from various puzzle sizes and difficulties */}
        <header>
          {/* list of puzzles. to choose a different one, it will pass the board size and location within the list */}
          <PuzzleSelector
            boardSize={this.state.boardSize}
            puzzleIndex={this.state.puzzleIndex}
            togglePause={(forcePause) => this.togglePause(forcePause)}
            changePuzzle={(boardSize, puzzleIndex) => this.changePuzzle(boardSize, puzzleIndex)}
          />
          {/* count-up timer (if settings allow; default is allowed) */}
          <PuzzleTimer
            timer={this.state.timer}
            stepNumber={this.state.stepNumber}
            paused={this.state.paused}
            togglePause={() => this.togglePause()}
            timerStart={() => this.timerStart()}
          />
          {/* version info and build date */}
          <DevInfo />
          {/* user-selected settings such as digit placement type and highlighting */}
          <PuzzleSettings
            shown={this.state.showSettings}
            userSettings={this.state.settings}
            changeSetting={(key, value) => this.changeSetting(key, value)}
            toggleSettings={() => this.toggleSettings()}
          />
        </header>
        {/* game board, digit picker, pencil mark picker, history, status */}
        <main className={mainClass}>
          {/* if the game is paused, cover everything so that the user cannot click the board or pickers */}
          <div className={pausedClass}></div>
          {/* contains the game boards (normal and history overlay) as well as the number picker */}
          <section className="boards">
            {/* always-displayed board of squares "the puzzle" */}
            <Board
              boardSize={this.state.boardSize}
              placedDigits={currentMove.digits}
              initialDigits={this.state.initialDigits}
              marks={currentMove.marks}
              currentSquare={this.state.currentSquare}
              digitHighlights={this.state.digitHighlights}
              rowColumnHighlights={this.state.rowColumnHighlights}
              onClick={(squareIndex) => this.squareClick(squareIndex)}
            />
            {/* board history as an overlay; only shows when hovering over a history item */}
            <ShadowBoard
              boardSize={this.state.boardSize}
              placedDigits={historyMove.digits}
              initialDigits={this.state.initialDigits}
              marks={historyMove.marks}
              shown={this.state.showShadow}
            />
            <div className="step-through">
              {/* undo button; does the same thing as clicking the number in history previous to current step */}
              <span className={undoClass} onClick={() => this.timeTravel(undoStep)}>undo</span>
              {/* redo button; does the same thing as clicking the number in history next to current step */}
              <span className={redoClass} onClick={() => this.timeTravel(redoStep)}>redo</span>
            </div>
          </section>
          {/* displays the number picker and solved status */}
          <section className="pickers">
            {/* the number picker to place a digit on the game board */}
            <DigitPicker
              boardSize={this.state.boardSize}
              activeDigit={this.state.activeDigit}
              clearDigit={this.state.clearDigit}
              clickDigit={(digitIndex) => this.digitClick(digitIndex)}
              clickClear={() => this.clearClick()}
            />
            {/* the number picker to place a pencil mark (notation for solution) on the game board */}
            <MarkPicker
              boardSize={this.state.boardSize}
              activeMark={this.state.activeMark}
              clickMark={(markIndex) => this.markClick(markIndex)}
            />
            {/* game status: solved/unsolved */}
            <div className={statusClass}>{this.state.solved ? 'solved' : 'unsolved'}</div>
          </section>
{/* @todo create a separate class for this functionality? */}
          {/* allow user to travel in time back and forth through their move history as well as showing each move as an overlay (on top of the normal board) on mouse hover */}
          <section className="history">{historyDisplay}</section>
        </main>
      </div>
    );
  }
}

ReactDOM.render(
  <Game />,
  document.getElementById('root')
);
